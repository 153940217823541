

.overlay
{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 1000;
}

.popup
{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 5%;
    z-index: 1000;
    border-radius: 15px;
}

.rid_underline
{
    text-decoration: none !important;
    color: black;
}

.icon
{
    display: inline-block;
    max-width: 35px;
    max-height: 35px;
    width: auto;
    height: auto;
    border-radius: 50px;
}




