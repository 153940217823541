.footer_1 {
    text-align: center;
    justify-content: center;
}

.inactiveLink {
    pointer-events: none;
    cursor: default;
 }

 .whitelink {
    text-decoration:none;
    color: white;
 }